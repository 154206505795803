<template>
  <div id="box">
    <el-button class="button" type="primary" @click="addListRow()" round>新增</el-button>
    <el-form ref="form" :model="form" label-width="80px" :rules="rules">
      <el-form-item label-width="0" prop="rows">
        <el-table
          :data="virtualRows"
          id="table"
          style="width: 100%;margin-bottom: 20px;"
          border
          row-key="parameterIndex"
          :expand-row-keys="expendArrs"
          ref="table"
          class="vtable"
          @expand-change="expendRow"
          :tree-props="{ children: 'children' }"
        >
          <el-table-column
            prop="parameterIndex"
            fixed
            label="序号"
            sortable
            width="180"
            v-slot="row"
          >
            <span class="expanded-icon-box">
              <i
                class="expanded-icon"
                v-if="row.hasChild"
                @click="expendRow(row, true)"
                >></i
              >
              {{row.parameterIndex}}
            </span>
          </el-table-column>

        <el-table-column
            prop="name"
            fixed
            label="参数名"
            width="150"
            :rules='rules.isName'
            v-slot="row"
          >
            <el-form-item :prop="`${
                   `rows.${row.path}.parameterName`
              }`" :rules='rules.isName'>
              <el-input style="width" v-model="row.parameterName"></el-input>
            </el-form-item>
          </el-table-column>

          <el-table-column prop="parameterType" label="参数类型">
            <template v-slot="row">
                <el-form-item :prop="`${
                     `rows.${row.path}.parameterType`
                  }`" :rules='rules.isParameterType'>
                <el-select v-model="row.parameterType">
                    <el-option v-for="item in parameterTypes" :key="item.value" :label="item.value" :value="item.key"/>
                </el-select>
                </el-form-item>
            </template>
          </el-table-column>

          <el-table-column prop="isRequired" label="是否必须">
            <template v-slot="row">
                <el-form-item :prop="`${
                      `rows.${row.path}.isRequired`
                  }`" :rules='rules.isRequired'>
                <el-select v-model="row.isRequired">
                    <el-option v-for="item in isRequiredOptions" :key="item.value" :label="item.value" :value="item.key"/>
                </el-select>
                </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="parameterComment" label="参数描述">
            <template v-slot="row">
              <el-form-item
                label-width="0"
                :rules="rules.parameterComment"
                :prop="
                  `${
                      `rows.${row.path}.parameterComment`
                  }`
                "
              >
                <el-input v-model="row.parameterComment"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="200px"
            fixed="right"
          >
            <template v-slot="scope">
              <el-button size="mini" :disabled="scope.row.parameterType != 'Object'" type="primary" @click="add(form.rows, scope.row.parameterIndex)">添加</el-button>
              <el-button size="mini" type="danger" @click="handleDelete(form.rows, scope.row.parameterIndex)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    tmpData: {
    }
  },
  data() {
    return {
      form: {
        rows: [],
      },
      rules: {
        isName: [{ required: true, message: "请输入名称"}],
        parameterComment: [{ required: true, message: "请输入参数描述"}],
        isParameterType: [{ required: true, message: '请选择参数类型' }],
        isRequired: [{ required: true, message: '请选择是否必须' }],
      },
      isRequiredOptions: [{ key: '01', value: '必须' }, { key: '00', value: '非必须' }],
      parameterTypes: [{ key: 'String', value: 'String' }, { key: 'Object', value: 'Object' }],
      positionType: true,
      startIndex: 0,
      virtualRows: [],
      scrollTop: 0,
      expendArrs: [],
      listLen: 0,
      startIndex: 0,
    };
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      find({ filter_eqs_interfaceId: this.tmpData.id }).then(res => {
        this.form.rows = res;
        this.setIndex(res);
      })
    },
    submitForm() {
      this.$refs.form.validate((valid, err) => {
        if (valid) {
          const paramslist = JSON.stringify(this.virtualRows)
          const formData = new FormData();
          formData.append("paramslist",paramslist)
          formData.append("interfaceId",this.tmpData.id)
          add(formData).then(response => {
            if (response) {
              einvAlert.success("提示",'保存成功')
              this.$emit('commit-success', true)
            } else {
              einvAlert.success("提示",'保存失败')
              this.$emit('commit-form', false)
            }
          }).catch(() => {
            this.$emit('commit-form', false)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    addListRow() {
      this.form.rows.push({
          interfaceId: this.tmpData.id,
          date: '',
          name: '',
          parameterComment: '',
          parameterType:'',
          parameterIndex: Date.parse(new Date()),
          children: [],
      })
      this.setIndex(this.form.rows);
    },
    handleDelete(arr, r) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].parameterIndex == r) {
          arr.splice(i, 1);
          this.virtualRows = [...arr];
          return;
        }
        if (arr[i].children && arr[i].children.length > 0) {
          this.handleDelete(arr[i].children, r);
          this.virtualRows = [...arr];
        }
      }
    },
    add(arr, r) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].parameterIndex == r) {
          arr[i].children.push({ name: "", parameterIndex: Date.parse(new Date()),children: [] ,interfaceId: this.tmpData.id,});
          this.virtualRows = [...arr]
          this.setIndex([...arr]);
          return;
        }
        if (arr[i].children && arr[i].children.length > 0) {
          this.add(arr[i].children, r);
          this.virtualRows = [...arr]
          this.setIndex([...arr]);
        }
      }
    },
    setIndex(data) {
        // console.log(data)
        this.virtualRows = [...data]
        let queue = [...data];
        let loop = 0;
        this.num = 0;
        this.expendArrs = [];
        this.listLen = 0;
        while (queue.length > 0) {
            loop++;
            [...queue].forEach((child, i) => {
            queue.shift();
            this.num++;
            if (loop == 1) {
                child.parameterIndex = i + 1 + "";
                child.currentIndex = i;
                child.path = i;
            }
            if (child.children && child.children.length > 0) {
                child.dataType = 1;
                for (let ci = 0; ci < child.children.length; ci++) {
                child.children[ci].currentIndex = ci;
                child.children[ci].parameterIndex =
                child.parameterIndex + "." + (ci + 1);
                child.children[ci].path = child.path + ".children." + ci;
                child.children[ci].pid = child.parameterIndex;
                }
                queue.push(...child.children);
            } else {
                child.dataType = 2;
            }
            child.expended = child.expended || "true";
            if (child.expended === "true") {
                this.expendArrs.push(child.parameterIndex);
            }
            });
            // console.log(this.expendArrs);
        }
    },

    expendRow(rows, expended) {
      this.DFS_Array(this.form.rows, (v) => {
        if (v.parameterIndex == rows.parameterIndex) {
          v.expended = String(expended);
          v.hasChild =
            v.expended === "false" && v.children.length > 0 ? true : false;
        }
      });
      if (!expended) {
        this.expendArrs = this.expendArrs.filter((v) => v !== rows.parameterIndex);
      } else {
        this.expendArrs.push(rows.parameterIndex);
      }
    },
    DFS_Array(arr, fn) {
      for (let i = 0; i < arr.length; i++) {
        fn(arr[i]);
        if (arr[i].children && arr[i].children.length > 0) {
          this.DFS_Array(arr[i].children, fn);
        }
      }
    },
  },
  watch: {
  },
};
</script>

<style scoped>
#box {
  font-family: Helvetica, sans-serif;
  text-align: center;
}
.button{
    float: left;
    margin-bottom: 10px;
    margin-top: -20px;
}
.rea-type {
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 100%;
  margin-top: -20px;
  border: 1px solid red;
}
.fix-type {
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 100%;
  border: 1px solid red;
  position: fixed;
  bottom: 0;
}
.outLogin {
  width: 36px;
  height: 36px;
  float: right;
  margin-top: 15px;
  margin-left: 10px;
}
.expanded-icon-box {
  position: relative;
}
.expanded-icon {
    display: none;
    position: absolute;
    cursor: pointer;
    left: -12px;
}
</style>
