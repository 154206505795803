var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"box"}},[_c('el-button',{staticClass:"button",attrs:{"type":"primary","round":""},on:{"click":function($event){return _vm.addListRow()}}},[_vm._v("新增")]),_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"80px","rules":_vm.rules}},[_c('el-form-item',{attrs:{"label-width":"0","prop":"rows"}},[_c('el-table',{ref:"table",staticClass:"vtable",staticStyle:{"width":"100%","margin-bottom":"20px"},attrs:{"data":_vm.virtualRows,"id":"table","border":"","row-key":"parameterIndex","expand-row-keys":_vm.expendArrs,"tree-props":{ children: 'children' }},on:{"expand-change":_vm.expendRow}},[_c('el-table-column',{attrs:{"prop":"parameterIndex","fixed":"","label":"序号","sortable":"","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(row){return [_c('span',{staticClass:"expanded-icon-box"},[(row.hasChild)?_c('i',{staticClass:"expanded-icon",on:{"click":function($event){return _vm.expendRow(row, true)}}},[_vm._v(">")]):_vm._e(),_vm._v(" "+_vm._s(row.parameterIndex)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"name","fixed":"","label":"参数名","width":"150","rules":_vm.rules.isName},scopedSlots:_vm._u([{key:"default",fn:function(row){return [_c('el-form-item',{attrs:{"prop":`${
                 `rows.${row.path}.parameterName`
            }`,"rules":_vm.rules.isName}},[_c('el-input',{staticStyle:{},model:{value:(row.parameterName),callback:function ($$v) {_vm.$set(row, "parameterName", $$v)},expression:"row.parameterName"}})],1)]}}])}),_c('el-table-column',{attrs:{"prop":"parameterType","label":"参数类型"},scopedSlots:_vm._u([{key:"default",fn:function(row){return [_c('el-form-item',{attrs:{"prop":`${
                   `rows.${row.path}.parameterType`
                }`,"rules":_vm.rules.isParameterType}},[_c('el-select',{model:{value:(row.parameterType),callback:function ($$v) {_vm.$set(row, "parameterType", $$v)},expression:"row.parameterType"}},_vm._l((_vm.parameterTypes),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.value,"value":item.key}})}),1)],1)]}}])}),_c('el-table-column',{attrs:{"prop":"isRequired","label":"是否必须"},scopedSlots:_vm._u([{key:"default",fn:function(row){return [_c('el-form-item',{attrs:{"prop":`${
                    `rows.${row.path}.isRequired`
                }`,"rules":_vm.rules.isRequired}},[_c('el-select',{model:{value:(row.isRequired),callback:function ($$v) {_vm.$set(row, "isRequired", $$v)},expression:"row.isRequired"}},_vm._l((_vm.isRequiredOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.value,"value":item.key}})}),1)],1)]}}])}),_c('el-table-column',{attrs:{"prop":"parameterComment","label":"参数描述"},scopedSlots:_vm._u([{key:"default",fn:function(row){return [_c('el-form-item',{attrs:{"label-width":"0","rules":_vm.rules.parameterComment,"prop":`${
                    `rows.${row.path}.parameterComment`
                }`}},[_c('el-input',{model:{value:(row.parameterComment),callback:function ($$v) {_vm.$set(row, "parameterComment", $$v)},expression:"row.parameterComment"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"200px","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"size":"mini","disabled":scope.row.parameterType != 'Object',"type":"primary"},on:{"click":function($event){return _vm.add(_vm.form.rows, scope.row.parameterIndex)}}},[_vm._v("添加")]),_c('el-button',{attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.handleDelete(_vm.form.rows, scope.row.parameterIndex)}}},[_vm._v("删除")])]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }